<template>
  <div>
    <div class="menuclass">
      <button @click="diaoyong">调用摄像头</button>
      <div>
        <button @click="close">关闭摄像头</button>
      </div>
      <div>
        <button @click="drawCanvas">获取当前帧</button>
           <input type="file" accept="image/*" value="88888" capture="camera">
      </div>
    </div>
    <video id="video" autoplay></video>
    <canvas id="canvasid"></canvas>
  </div>
</template>
<script>
export default {
  data() {
    return {
      video: "",
      streams: "",
      canvas: "",
      videoConfig: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.canvas = document.getElementById("canvasid");
      this.video = document.querySelector("#video");
      let height = document.body.offsetHeight;
      let width = document.body.offsetWidth;
      this.canvas.width = width;
      this.canvas.height = height;
      this.video.width = width;
      this.video.height = height;
      this.videoConfig = { width: width, height: height };

      let frame = document.createDocumentFragment();
      let dom = document.createElement("div");
      dom.textContent = "123";
      dom.id = "aaaa";
      frame.appendChild(dom);
     
      document.body.appendChild(frame);
      console.log(document.getElementById("aaaa").offsetHeight);

    },
    //打开摄像头
    diaoyong() {
      alert(888);
      var that = this;
      navigator.mediaDevices
        .getUserMedia({ video: this.videoConfig })
        .then(function (stream) {
          console.log(stream);
          that.streams = stream;
          // Older browsers may not have srcObject
          if ("srcObject" in that.video) {
            that.video.srcObject = stream;
          } else {
            // Avoid using this in new browsers, as it is going away.
            that.video.src = window.URL.createObjectURL(stream);
          }

          that.video.onloadedmetadata = function (e) {
            that.video.play();
          };
          /* 使用这个stream stream */
        })
        .catch(function (err) {
          console.error(err);
          /* 处理error */
        });
    },
    //关闭摄像头
    close() {
      this.streams.getTracks().forEach(function (track) {
        track.stop();
      });
    },
    //获取某一帧
    drawCanvas() {
      console.log("获取当前帧");
      let ctx = this.canvas.getContext("2d");
      ctx.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    },
  },
};
</script>
<style>
.menuclass {
  left: 0;
  top: 0;
  z-index: 3000;
}
</style>